import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-scroll'
import cn from 'classnames'
import Icon from '@ui/icon'
import { MenuType } from '@utils/types'

const MainMenu = ({ menus, className, navId }) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)

	const publicationsMenu = menus.filter(menu => menu.path === 'book-chapters' || menu.path === 'research-and-publications' || menu.path === 'conference-papers' || menu.path === 'journal-publications')

	const otherMenus = menus.filter(menu => menu.path !== 'book-chapters' && menu.path !== 'research-and-publications' && menu.path !== 'conference-papers' && menu.path !== 'journal-publications')

	return (
		<nav id={navId} className={cn('mainmenu-nav navbar-example2', className)}>
			<ul className='primary-menu nav nav-pills'>
				{otherMenus.map(({ id, text, path, icon }) => (
					<li key={id} className='nav-item'>
						<Link activeClass='active' className='nav-link smoth-animation' href={`#${path}`} to={path} spy={true} smooth={true} offset={-50} duration={500}>
							{icon && <Icon name={icon} />}
							{text}
						</Link>
					</li>
				))}

				<li className='nav-item dropdown'>
					<button className={cn('nav-link smoth-animation dropdown-toggle', isDropdownOpen && 'show')} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
						Publications
					</button>
					<div className={cn('dropdown-menu', isDropdownOpen && 'show')}>
						{publicationsMenu.map(({ id, text, path }) => (
							<Link key={id} activeClass='active' className='dropdown-item smoth-animation' href={`#${path}`} to={path} spy={true} smooth={true} offset={-50} duration={500} onClick={() => setIsDropdownOpen(false)}>
								{text}
							</Link>
						))}
					</div>
				</li>
			</ul>
		</nav>
	)
}

MainMenu.propTypes = {
	menus: PropTypes.arrayOf(PropTypes.shape(MenuType)).isRequired,
	navId: PropTypes.string,
	className: PropTypes.string,
}

MainMenu.defaultProps = {
	navId: 'sideNav',
}

export default MainMenu
