import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FileText } from 'react-feather'
import JournalModal from '@components/modal-journal'

const JournalCard = ({ title, description, path }) => {
	const [show, setShow] = useState(false)
	return (
		<>
			<div className='rn-journal' onClick={() => setShow(true)} onKeyPress={() => setShow(true)} role='button' tabIndex='-1'>
				<div className='inner'>
					<div className='content'>
						<div className='icon'>
							<FileText size={24} />
						</div>
						<h4 className='title'>
							<a href='#!'>{title}</a>
						</h4>
						<p className='description'>{description.substring(0, 150)}...</p>
					</div>
				</div>
			</div>
			<JournalModal show={show} setShow={setShow} title={title} description={description} path={path} />
		</>
	)
}

JournalCard.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
}

export default JournalCard
