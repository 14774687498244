import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { X, ChevronRight } from 'react-feather'
import Button from '@ui/button'

const JournalModal = ({ show, setShow, title, description, path }) => {
	return (
		<Modal show={show} onHide={() => setShow(false)} dialogClassName='modal-90w' aria-labelledby='example-custom-modal-styling-title' centered={true}>
			<Modal.Header>
				<Modal.Title id='example-custom-modal-styling-title' className='sr-only'>
					Journal Publication Details
				</Modal.Title>
				<button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={() => setShow(false)}>
					<span aria-hidden='true'>
						<X />
					</span>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className='row align-items-center'>
					<div className='col-lg-12'>
						<div className='text-content'>
							<h3>{title}</h3>
							<p className='mb--30'>{description}</p>
							<div className='button-group mt--20'>
								<Button path={path}>
									<span>VIEW PUBLICATION</span>
									<ChevronRight />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

JournalModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
}

export default JournalModal
