import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from '@components/section-title'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { SectionTitleType, ImageType } from '@utils/types'

const MapArea = ({ data, id }) => {
	return (
		<div className='rn-service-area rn-section-gap section-separator' id={id}>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-12'>{data?.section_title && <SectionTitle data-aos='fade-up' data-aos-duration='500' data-aos-delay='100' data-aos-once='true' {...data.section_title} align='center' />}</div>
				</div>
				<div className='row mt--30'>
					<div className='col-lg-12'>
						{data?.images?.[0]?.src?.childImageSharp?.gatsbyImageData && (
							<div className='map-image' data-aos='fade-up' data-aos-duration='500' data-aos-delay='100' data-aos-once='true'>
								<GatsbyImage image={getImage(data.images[0].src)} alt='Global Research Impact Map' className='w-100' />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

MapArea.propTypes = {
	id: PropTypes.string,
	data: PropTypes.shape({
		section_title: PropTypes.shape(SectionTitleType),
		images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
	}),
}

MapArea.defaultProps = {
	id: 'global-map',
}

export default MapArea
